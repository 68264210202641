import { Container } from "@material-ui/core"
import React from "react"
import { Layout } from "../components/layout"

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout title={"Graduate Fashion Week - 404"}>
      <Container>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
